<template>
  <div class="sellProducts">
    <div
      style="display:flex; gap: 20px; margin-bottom: 5px;"
    >
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSearch === 'ArticleNumber' }"
            @click="showArticleNumber(), toggleSearch = 'ArticleNumber'"
          >
            {{ $t('ArticleNumber') }}
          </p>
          <p
            :class="{ active2: toggleSearch === 'Code' }"
            @click="showCode(), toggleSearch = 'Code'"
          >
            {{ $t('Code') }}
          </p>
          <p
            :class="{ active2: toggleSearch === 'Category' }"
            @click="showCategory(), toggleSearch = 'Category'"
          >
            {{ $t('Category') }}
          </p>
        </div>
      </div>
      <div v-if="toggleSearch === 'ArticleNumber'">
        <div class="black">
          <b-icon-search
            v-if="searchArticle == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 12px"
          />
          <b-icon-x-circle
            v-else-if="searchArticle != '' && !noSearch"
            class="search1"
            style="position: absolute; right: 20px; top: 12px; cursor: pointer"
            @click="searchArticle = ''"
          />
          <input
            v-model="searchArticle"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('ArticleNumber')"
            :style="getArticleNameLike.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
            @keyup="inputChanged"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
          >
        </div>
        <div
          v-if="getArticleNameLike.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getArticleNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getArticleNameLike"
            :key="index"
            ref="options"
            style="cursor: pointer"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="searchByOn(result); noSearch = false"
          >
            <p style="margin: 0">
              {{ result.articleNumber }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="toggleSearch === 'Code'">
        <div class="black">
          <b-icon-search
            v-if="searchCode == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 12px"
          />
          <b-icon-x-circle
            v-else-if="searchCode != '' && !noSearch"
            class="search1"
            style="position: absolute; right: 20px; top: 12px; cursor: pointer"
            @click="searchCode = ''"
          />
          <input
            v-model="searchCode"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('Code')"
            :style="getFilteredDresses.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
            @keyup="inputChangedCode"
            @keydown.down="onArrowDownCode"
            @keydown.up="onArrowUpCode"
          >
        </div>
        <div
          v-if="getFilteredDresses.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredDresses"
            :key="index"
            ref="options"
            style="cursor: pointer"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="searchByCode(result); noSearch = false"
          >
            <p style="margin: 0">
              {{ result.name + (result.code ? ' ' + result.code : '') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="toggleSearch === 'Category'">
        <div class="black">
          <b-icon-search
            v-if="searchCategory == ''"
            class="search1"
            style="position: absolute; left: 5px; top: 12px"
          />
          <b-icon-x-circle
            v-else-if="searchCategory != '' && !noSearch"
            class="search1"
            style="position: absolute; right: 20px; top: 12px; cursor: pointer"
            @click="searchCategory = ''"
          />
          <input
            v-model="searchCategory"
            type="text"
            autocomplete="off"
            name="search-box"
            :placeholder="$t('Category')"
            :style="getFilteredDresses.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
            @keyup="inputChangedCategory"
            @keydown.down="onArrowDownCategory"
            @keydown.up="onArrowUpCategory"
          >
        </div>
        <div
          v-if="getFilteredDresses.length > 0 && noSearch"
          ref="scrollContainer"
          class="dropdrop"
          :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
        >
          <div
            v-for="(result, index) in getFilteredDresses"
            :key="index"
            ref="options"
            style="cursor: pointer"
            class="search-item"
            :class="{ 'is-active': index === arrowCounter }"
            @click="searchByCategory(result); noSearch = false"
          >
            <p style="margin: 0">
              {{ result.name + (result.code ? ' ' + result.code : '') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead>
          <tr>
            <th>
              {{ $t("No") }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('Description') }}
            </th>
            <th>
              {{ $t('Amount') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('Photo') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(products, index) in getProductStockSell"
            :key="index"
            v-b-tooltip.hover
            class="hover-row"
            :style="classNameCh === products.articleNumber ? 'background-color: rgba(255, 39, 79, 0.1)' : ''"
            style="cursor:pointer"
            :title="$t('ClickToSeeLocation')"
            @click="matProps(products)"
          >
            <td>
              {{ getSellableProductsTotalItems - (pageNumber - 1) * pageSize - index }}.
            </td>
            <td>{{ $t(products.articleNumber) }}</td>
            <td>{{ $t(products.description) }}</td>
            <td>{{ $t(products.totalAmountInStock) }}</td>
            <td>{{ $t(products.price) }}</td>
            <td>
              <img
                v-b-tooltip="$t('ClickToViewPhoto')"
                style="height: 40px; border-radius: 3px;cursor:pointer; "
                :src="products.photos"
                alt=""
                @click.stop="getAllImages(products)"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <b-pagination
        v-if="getSellableProductsTotalItems > 15"
        v-model="pageNumber"
        :total-rows="getSellableProductsTotalItems"
        :per-page="pageSize"
        first-number
        last-number
        align="fill"
        size="md"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
      >
        <template #prev-text>
          <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
        </template>
        <template #next-text>
          <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
        </template>
      </b-pagination>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'jspdf-autotable';
import { client } from '@/domainConfig'
import VueEasyLightbox from 'vue-easy-lightbox'



export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      client: client.clientName,
      toggleSearch: 'ArticleNumber',
      articleNumber: null,
      pageNumber: 1,
      pageSize: 15,
      classNameCh: '',
      searchArticle: '',
      searchCode: '',
      searchCategory: '',
      arrowCounter: 0,
      noSearch: false,
      orderNumberClient: null,
      addressOfClient: null,
      phoneOfClient: null,
      emailOfClient: null,
      nameOfClient: null,
      image: {
        zoom: 1,
        imgs: [],
        visible: false,
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['getInvoiceByOrderNumber', 'getProductStockSell', 'getSellableProductsTotalItems', 'getArticleNumberNameLike', 'getArticleNameLike', 'getFilteredDresses']),
  },
  watch: {
    pageNumber(newValue) {
      this.sellableProducts(
        {
          pageNumber: newValue,
          pageSize: this.pageSize,
          articleNumber: this.searchCategory != '' || this.searchCode || this.searchArticle != '' ? this.searchCategory || this.searchCode || this.searchArticle : null,
          description: null,
        },
      )
    },
    pageSize(newValue) {
      this.sellableProducts({ pageNumber: this.pageNumber, pageSize: newValue })
    },
    searchArticle(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.sellableProducts({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            articleNumber: null,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.productInStock_articleNumberLike(value);
          this.noSearch = true
        }
      }, 500);
    },
    searchCode(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.sellableProducts({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            articleNumber: null,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressesByName({
            code: value,
            name: null,
          });
          this.noSearch = true
        }
      }, 500);
    },
    searchCategory(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value == '') {
          this.removeLists()
          this.sellableProducts({
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            articleNumber: null,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
          this.filteredDressesByName({
            code: null,
            name: value,
          });
          this.noSearch = true
        }
      }, 500);
    },
  },
  mounted() {
    this.sellableProducts({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    })
  },
  methods: {
    ...mapActions(['getPendingOrTransitItems', 'filteredDressesByName', 'movingLocations', 'productInStock_location_Pagination', 'loadInvoiceByOrderNumber', 'accountDetails', 'usersByMultipleRoles', 'sellOrderInStock', 'loadOrderTypes', 'sellableProducts', 'totalStockGroupedByLocation', 'articleNumberNameLike', 'resetFilteredMVBySearch', 'productInStock_articleNumberLike']),
    async getAllImages(item) {
      // Set the array to an empty state at the beginning
      this.image.imgs = [];

      // Filter images based on the materialVariantId
      const filteredImages = this.getProductStockSell.filter((image) => {
        return image.articleNumber === item.articleNumber;
      });
      // Fetch and push the filtered images
      for (let i = 0; i < filteredImages.length; i++) {
        const image = filteredImages[i];
        try {
          // Access the single photo in the 'image.photos' property and push it to 'this.image.imgs'
          this.image.imgs.push({
            src: image.photos, // Assign the fetched image
          });
        } catch (e) {
          console.log(e);
        }
      }

      // Continue with your other logic
      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
    showArticleNumber() {
      this.toggleSearch = 'ArticleNumber'
      this.arrowCounter = 0
      this.noSearch = false
      this.searchCode = ''
      this.searchCategory = ''
      this.enterPressed = false
    },
    showCode() {
      this.toggleSearch = 'Code'
      this.arrowCounter = 0
      this.noSearch = false
      this.searchArticle = ''
      this.searchCategory = ''
      this.enterPressed = false
    },
    showCategory() {
      this.toggleSearch = 'Category'
      this.arrowCounter = 0
      this.noSearch = false
      this.searchArticle = ''
      this.searchCode = ''
      this.enterPressed = false
    },
    matProps(value) {
      this.classNameCh = value.articleNumber
      this.totalStockGroupedByLocation({ articleNumber: value.articleNumber })
      this.$emit('articleNumber', value.articleNumber)
      this.$emit('resetShowPending');
    },
    orderTypes() {
      this.loadOrderTypes()
      this.accountDetails({
        isActive: true,
      })
      this.usersByMultipleRoles({
        status: true,
        object: ['SalesPerson', 'OnlineSales', 'Designer'],
      })
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getArticleNameLike = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getArticleNameLike[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedCategory(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getArticleNameLike = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCategory(this.getFilteredDresses[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownCategory(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpCategory(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedCode(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredDresses = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCode(this.getFilteredDresses[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownCode(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredDresses.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpCode(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      if (this.arrowCounter <= 16) {
        this.$refs.scrollContainer.scrollTop = 34 * this.arrowCounter;
      } else {
        this.$refs.scrollContainer.scrollTop = 58 * this.arrowCounter;
      }
    },
    async searchByOn(value) {
      this.searchArticle = value.articleNumber;
      this.noSearch = false;
      await this.sellableProducts({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        articleNumber: this.searchArticle,
      })
    },
    async searchByCategory(value) {
      this.searchCategory = value.name;
      this.noSearch = false;
      await this.sellableProducts({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        articleNumber: this.searchCategory,
        description: null,
      })
    },
    async searchByCode(value) {
      this.searchCode = value.code;
      this.noSearch = false;
      await this.sellableProducts({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        articleNumber: this.searchCode,
        description: null,
      })
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredMVBySearch();
    },
  },
}
</script>

<style scoped lang="scss">
.prev-itemi {
  padding-right: 340px !important;
}
.sellProducts {
  width: 50%;
}
.next-itemi {
  padding-left: 340px !important;
}
.number {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: red;
  color: white;
  position: relative;
  bottom: 50%;
  left: 75%;
  border:1px solid white;
}

.team_table td {
  padding: 12px 9px !important;
}

.team_table th {
  // text-align: center;
}

.editButton {
  height: 30px;
}
.active2 {
  font-weight: bold;
  color: $base-color;
}
.active2{
  color: $base-color;
  font-weight: bold;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.mats-listing__wrapper {
  .mats-listing{
    margin-top: 0
  }
}
  .button {
    margin-bottom: 15px!important;
  }
  .mat {
    display: flex;
    justify-content: space-between;
    align-content: center;
    &.active {
      background-color: #e8e8e8;
    }
    div {
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
    span {
      margin-left: 10px;
      svg {
        font-size: 15px;
      }
    }
  }
  .team_table {
  font-size: 12px;
  border-radius: 8px 8px 8px 8px !important;
}

tr:last-child {
  border-bottom: none !important;
}

table:hover {
  cursor: pointer;
}
.dropdrop::-webkit-scrollbar {
display: none;

}
.dropdrop {
display: inline-block;
overflow: auto;
position: absolute;
background: white;
width: 227px;
box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}
.search-item:last-child {
  border-bottom: none;
}
.search-item:hover {
  background: #82868c;
  color: white;
}
.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}
.is-active {
  background-color: #dedede;
}
@media screen and (max-width: 1200px) {
  .sellProducts {
    width: 100%;
  }
}
</style>
